<template>
  <div class="tableSearch flex-fill">
    <b-row>
      <b-col
        cols="12"
        class="d-flex align-items-center justify-content-start"
      >
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="searchQuery"
            debounce="500"
            :placeholder="t('dictionary.search')"
          />
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BInputGroup, BInputGroupPrepend, BFormInput,
} from 'bootstrap-vue';
import { toRefs, computed } from '@vue/composition-api';
import { useI18n } from '@/hooks/useI18n';

export default {
  components: {
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
  },
  props: {
    query: String,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { query } = toRefs(props);

    const searchQuery = computed({
      get() {
        return query.value;
      },
      set(value) {
        emit('update:query', value);
      },
    });

    return {
      t,
      searchQuery,
    };
  },
};
</script>

<style lang="sass">
.tableSearch
  max-width: 341px
</style>
